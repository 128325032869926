body 
{
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 1.75;
}
.active{
    color: #3f48c4 !important;
    font-weight: 600;
    font-size: 16px;
}
.nav-link{
    font-size: 16px;
}
.border-bottom-menu
{
    border-bottom: 1px solid #e7eaf0;

}
.btn--color 
{
    background-color: #fa9529;
}
.logo-img {
    max-width: 280px;
}
/* .bg-overlay:before 
{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(40, 122, 199, 0.93);
} */
.section-wrap 
{
    padding: 150px 0;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
.bottom-divider {
    border-bottom: 1px solid #e7eaf0;
}
.text-justify{
    text-align: justify;
}
.hero 
{
    background-size: cover;
    background-position: 50%;
    height: 100%;
    position: relative;
}
.hero__title 
{
    font-size: 38px;
    line-height: 1.3;
    margin-bottom: 24px;
    font-weight: 400;
}
.intro__title 
{
    margin-bottom: 0;
    font-size: 25px;
    line-height: 1.6;
}
.intro__subtitle{
    color: #8b95a3;
    font-size: 13px;
margin-bottom: 15px;
display: inline-block;
}
.c-3e4045{
    color: #3e4045;
}
.c-8b95a3{
    color: #8b95a3;
}
.c-fa9529{
    color: #ffffff;
    font-weight: 600;
}
.estio-lista 
{
    list-style: none;
    padding-left: 20px;
    text-align: justify;
}
.ui-check:before 
{
    content: '\e80a';
}
.pb-100 
{
    padding-bottom: 100px;
}
.pt-100 
{
    padding-top: 100px;
}
#BarraContacto {
    content: "";
    background-color: rgba(40, 122, 199, 0.93);
}

.socials--nobase 
    {
    width: auto;
    height: auto;
    line-height: 1;
    margin-right: 24px;
    background-color: transparent;
    color: #8b95a3;
}
.lead 
{
    font-size: 18px;
    line-height: 30px;
}
.pointer{
    cursor: pointer;
}
@media only screen and (max-width: 767px)
{
    .hero__container, .hero__container--short, .hero__container--medium {
        height: auto !important;
        padding: 60px 15px;
        }
}
